import { getApp, getApps, initializeApp } from 'firebase/app'
import * as firebaseAuth from 'firebase/auth'

import { createAuth } from '@redwoodjs/auth-firebase-web'

export interface XweaveCurrentUser {
  id: string
  email: string
  phone: string
  name: string
  firebaseUID: string
  metadata: Record<string, any>
  membership: Array<{
    id: string
    role: string
    organization: {
      id: string
      displayName: string
      code: string
    }
  }>
  activeMembership: {
    id: string
    role: string
    organizationId: string
    organization: {
      id: string
      displayName: string
      code: string
    }
  }
}
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
}

const firebaseApp = ((config) => {
  const apps = getApps()

  if (!apps.length) {
    initializeApp(config)
  }

  return getApp()
})(firebaseConfig)

export const firebaseClient = {
  firebaseAuth,
  firebaseApp, // optional
}

export const { AuthProvider, useAuth } = createAuth(firebaseClient)
