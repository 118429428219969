import { useLocation } from '@redwoodjs/router'

const SidebarButton = ({ children, match }) => {
  const { pathname } = useLocation()
  return (
    <div
      className={`rounded-md px-1 py-2 ${
        pathname.includes(match)
          ? 'bg-gray-300 font-semibold dark:bg-gray-800'
          : ''
      }`}
    >
      <div
        className={`${pathname.includes(match) ? 'text-gray-800' : 'text-gray-300'}`}
      >
        {children}
      </div>
    </div>
  )
}

export default SidebarButton
