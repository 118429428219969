import 'src/globals.css'

import * as theme from '../config/chakra.config'

import { AuthProvider, useAuth } from 'src/lib/auth'
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { RedwoodProvider } from '@redwoodjs/web'
import Routes from 'src/Routes'
import Sentry from 'src/lib/sentry'

const extendedTheme = extendTheme(theme)

const App = () => (
  // @ts-ignore
  <Sentry.ErrorBoundary fallback={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%AppTitle">
      <ColorModeScript />
      <ChakraProvider>
        <AuthProvider>
          <RedwoodApolloProvider useAuth={useAuth}>
            <Routes />
          </RedwoodApolloProvider>
        </AuthProvider>
      </ChakraProvider>
    </RedwoodProvider>
  </Sentry.ErrorBoundary>
)

export default App
